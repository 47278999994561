// const { captureException } = require('@sentry/gatsby');
const { default: axios } = require('axios');

const authorsEndpoint = 'https://api.intercom.io/admins';
const collectionsEndpoint = 'https://api.intercom.io/help_center/collections';
const sectionsEndpoint = 'https://api.intercom.io/help_center/sections';
const articlesEndpoint = 'https://api.intercom.io/articles';

const config = {
  headers: {
    Authorization: `Bearer ${process.env.INTERCOM_ACCESS_TOKEN}`,
    'Content-Type': 'application/json',
  },
};

const toCamelCase = string =>
  string
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (_m, chr) => chr.toUpperCase());

const camelizeKeys = object => {
  const newObject = {};

  Object.entries(object).forEach(([key, value]) => {
    newObject[toCamelCase(key)] = value;
  });

  return newObject;
};

const getHelpCenterCollections = async () => {
  const {
    data: { data: collections },
  } = await axios.get(collectionsEndpoint, config).catch(err => {
    console.log(`Failure Getting Help Center Collections - ${err}`);
  });

  return collections.map(collection => {
    const collectionCopy = { ...collection };

    collectionCopy.name = collectionCopy.name.trim();

    return camelizeKeys(collectionCopy);
  });
};

const getHelpCenterSections = async () => {
  const {
    data: { data: sections },
  } = await axios.get(sectionsEndpoint, config).catch(err => {
    console.log(`Failure Getting Help Center Sections - ${err}`);
  });

  return sections.map(section => camelizeKeys(section));
};

const getPaginatedHelpArticles = async (page = 1) => {
  const articlesConfig = {
    ...config,
    params: { per_page: 250, page }, // 250 is the max supported page_size
  };
  const {
    data: {
      pages: { next },
      data: articles,
    },
  } = await axios.get(articlesEndpoint, articlesConfig).catch(err => {
    console.log(`Failure Getting Help Articles - ${err}`);
  });

  return {
    articles: articles
      .filter(article => article.state === 'published' && article.url !== null)
      .map(article => camelizeKeys(article)),
    hasMore: !!next,
  };
};

const getHelpCenterArticles = async () => {
  let page = 1;
  const initialResponse = await getPaginatedHelpArticles(page);
  let { articles, hasMore } = initialResponse;

  while (hasMore) {
    page += 1;

    const {
      articles: newArticles,
      hasMore: newHasMore,
    } = await getPaginatedHelpArticles(page);

    articles = [...articles, ...newArticles];
    hasMore = newHasMore;
  }

  return articles;
};

const getHelpCenterAuthors = async () => {
  const {
    data: { admins: authors },
  } = await axios.get(authorsEndpoint, config).catch(err => {
    console.log(`Failure Getting Help Center Authors - ${err}`);
  });

  return authors.map(author => camelizeKeys(author));
};

const getUpdatedArticleBody = (
  body,
  articleUrlsById,
  collectionParamsById,
  currentArticleUrl
) => {
  let newBody = body;

  // Bump all headers down one level if an <h1> is present
  if (newBody.includes('<h1')) {
    newBody = newBody.replace(
      /<(\/?h)(\d)([^>]*)>/gi,
      (_match, headerTag, headerLevel, attributes) => {
        const newHeaderLevel = Math.min(6, parseInt(headerLevel, 10) + 1);

        return `<${headerTag}${newHeaderLevel}${attributes}>`;
      }
    );
  }

  // Update links to other articles
  newBody = newBody.replace(
    /(?:target="_blank" )?href="(?:https?:\/\/intercom\.help\/shopmonkeyio\/en\/articles\/|https?:\/\/help\.shopmonkey\.io\/en\/articles\/)(\d+)-(?:[-a-z\d]*)"(?: target="_blank")?/gm,
    (match, id) => {
      // Get the URL based on ID because the link might point to an old slug that redirects
      const url = articleUrlsById[id];

      if (url) {
        const slug = url
          .split('/')
          .pop()
          .replace(/\d+-/, '');

        return `href="/help/${slug}"`;
      }

      // If the article that the link is for no longer exists, ignore it to prevent build errors
      console.log(
        '\n',
        `Article ${currentArticleUrl} links to another article that was not found (${
          match.match(/href="(.*?)"/)[1]
        })`,
        '\n'
      );
      return match;
    }
  );

  // Update links to collections
  newBody = newBody.replace(
    /(?:target="_blank" )?href="(?:https?:\/\/intercom\.help\/shopmonkeyio\/en\/collections\/|https?:\/\/help\.shopmonkey\.io\/en\/collections\/)(\d+)-(?:[-a-z\d]*)"(?: target="_blank")?/gm,
    (_match, id) => {
      // Get the URL param for the collection
      const param = collectionParamsById[id];

      return `href="/help?${param}"`;
    }
  );

  // Update links to Help Center home
  newBody = newBody.replace(
    /https?:\/\/help\.shopmonkey\.io\/en\/?"/g,
    'https://www.shopmonkey.io/help"'
  );

  // Remove "Still have questions?" section if it exists
  if (newBody.includes('Still have questions?')) {
    newBody = newBody.substring(0, newBody.lastIndexOf('<hr>'));
  }

  return newBody;
};

exports.getHelpCenterAuthors = getHelpCenterAuthors;
exports.getHelpCenterCollections = getHelpCenterCollections;
exports.getHelpCenterSections = getHelpCenterSections;
exports.getHelpCenterArticles = getHelpCenterArticles;
exports.getUpdatedArticleBody = getUpdatedArticleBody;
